angular.module('sq.serverEvents.directive', 
['sq.serverEvents.controller'])
.directive('serverEvents', function() {
  return {
    scope: {},
    restrict: 'AE',
    controller: 'ServerEventsController',
    controllerAs: 'sEctrl',
    templateUrl: 'serverEvents/serverEvents.tpl.html',
  };
});